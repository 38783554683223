import { Edit, FileDownload, PlayOutline } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Form,
  Grid,
  IconButton,
  Modal,
  Row,
  Schema,
} from "rsuite";
import DataTable from "../../components/dataTable";
import Page from "../../components/layout/Page";
import { getEnumName, initParams } from "../../utils";
import { LessonVideoTypesEnum } from "../../utils/enums";
import {
  useGetLessonDocumentsQuery,
  useGetStudentLessonDocumentsQuery,
} from "../../redux/lessonDocumentApi";
import TextInput from "../../components/TextInput";
import RefDropdown from "../../components/RefDropdown";
const { NumberType } = Schema.Types;

const MyDocuments = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);

  const { data, isLoading, refetch } = useGetLessonDocumentsQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const edit = (data) => {};

  const columns = [
    {
      title: t("pages.lessonVideo.educationId"),
      name: "educationId",
      sortable: true,
      align: "left",
      type: "ref",
      fullText: true,
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>
          {rowData.lessonDocument_education
            ? rowData.lessonDocument_education?.code +
              " - " +
              rowData.lessonDocument_education?.name
            : ""}
        </span>
      ),
    },
    // {
    //   title: t("pages.lessonVideo.lessonId"),
    //   name: "lessonId",
    //   sortable: true,
    //   align: "left",
    //   type: "ref",
    //   flexGrow: 1,
    //   renderCell: (rowData) => (
    //     <span>
    //       {rowData.lessonDocument_lesson?.lessonVideo_lesson
    //         ? rowData.lessonDocument_lesson?.code +
    //           " - " +
    //           rowData.lessonDocument_lesson?.name
    //         : ""}
    //     </span>
    //   ),
    // },
    {
      title: t("pages.lessonDocument.name"),
      name: "name",
      sortable: true,
      align: "left",
      type: "text",
      fullText: true,
      flexGrow: 1,
    },
    {
      title: t("pages.lessonDocument.description"),
      name: "description",
      sortable: true,
      align: "left",
      type: "text",
      fullText: true,
      flexGrow: 1,
    },
    // {
    //   title: t("pages.lessonVideo.videoTypeId"),
    //   name: "description",
    //   sortable: true,
    //   align: "left",
    //   type: "text",
    //   flexGrow: 1,
    //   renderCell: (rowData) => (
    //     <>
    //       {t(
    //         `general.enums.videoType.${getEnumName(
    //           LessonVideoTypesEnum,
    //           rowData.videoTypeId
    //         )}`
    //       )}
    //     </>
    //   ),
    // },
    // {
    //   title: t("pages.lessonVideo.isAccessAll"),
    //   name: "isAccessAll",
    //   sortable: true,
    //   align: "left",
    //   type: "boolean",
    //   flexGrow: 1,
    //   renderCell: (rowData) => (
    //     <span>{rowData.isAccessAll ? t("general.yes") : t("general.no")}</span>
    //   ),
    // },
    {
      title: t("general.table.actions"),
      name: "actions",
      width: 100,
      renderCell: (rowData) => (
        <>
          {/* <a
            download={rowData?.path?.substring(
              rowData?.path.lastIndexOf("/") + 1
            )}
            target="_blank"
            href={`https://admin.icba.uk/static${rowData?.path}`}
          >
            İndir
          </a> */}
          <a
                  // download={rowData?.path?.substring(
                  //   rowData?.path.lastIndexOf("/") + 1
                  // )}
                  download={`https://admin.icba.uk/api/public/documents/${rowData?.path}`}
                  target="_blank"
                  href={`https://admin.icba.uk/api/public/documents/${rowData?.path}`}
                >
                  İndir
                </a>
        </>
      ),
    },
  ];

  return (
    <Page title={t("pages.lessonDocument.title")}>
      <DataTable
        size="compact"
        columns={columns}
        rowKey="id"
        loading={isLoading}
        data={data?.data}
        params={params}
        rowCount={data?.total}
        setPagination={setParams}
        refetch={refetch}
      />
    </Page>
  );
};

export default MyDocuments;
