import { api } from "./api";
const apiUrl = "https://admin.icba.uk/api";
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addEducation: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/education/add`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    updateEducation: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/education/update`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getEducations: builder.query({
      query: (data) => ({
        url: `${apiUrl}/education/student/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getEducationsById: builder.query({
      query: (data) => ({
        url: `${apiUrl}/education/by-id`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddEducationMutation,
  useGetEducationsQuery,
  useUpdateEducationMutation,
  useGetEducationsByIdQuery,
} = extendedApi;
