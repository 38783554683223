import { api } from "./api";
const apiUrl = "https://admin.icba.uk/api";
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addLesson: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/lesson/add`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    updateLesson: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/lesson/update`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getLessons: builder.query({
      query: (data) => ({
        url: `${apiUrl}/lesson/student/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getLessonsById: builder.query({
      query: (data) => ({
        url: `${apiUrl}/lesson/by-id`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddLessonMutation,
  useGetLessonsQuery,
  useUpdateLessonMutation,
  useGetLessonsByIdQuery,
} = extendedApi;
