import React from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Col,
  Form,
  Grid,
  Loader,
  Panel,
  Row,
  useToaster,
} from "rsuite";
import Page from "../../../components/layout/Page";
import TextArea from "../../../components/TextArea";
import AdminIcon from "@rsuite/icons/Admin";
import SearchCountry from "../../../components/SearchCountry";
import EnumDropdown from "../../../components/EnumDropdown";
import TextInput from "../../../components/TextInput";
import { useGetStudentByIdQuery } from "../../../redux/studentApi";
import { GenderOptions } from "../../../utils/options";

const Profile = ({ student, fetchLoading }) => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const [formValues, setFormValue] = React.useState(student);
  React.useEffect(() => {
    if (student) {
      setFormValue(student);
    }
  }, [student]);

  if (fetchLoading) {
    return (
      <Loader
        vertical
        backdrop
        center
        size="lg"
        content={`${t("general.loading")}...`}
      />
    );
  }
  return (
    <Form
      fluid
      formValue={formValues}
      ref={formRef}
      disabled={fetchLoading}
      plaintext
    >
      <Panel
        header="Genel Bilgiler"
        bordered
        shaded
        bodyFill
        style={{ padding: 20 }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ textAlign: "center", marginBottom: 20 }}
        >
          {formValues?.photoPath ?
            <img style={{ height: 150, width: 150 }} src={`https://admin.icba.uk/api/public/documents/${formValues?.photoPath}`} alt={formValues?.photoPath} />
            : <Avatar size="lg" style={{ height: 150, width: 150 }}>
              <AdminIcon style={{ height: 200 }} />
            </Avatar>}
          {/* {process.env.NODE_ENV !== "production" ? (
            <Avatar
              size="lg"
              style={{ height: 100, width: 100 }}
              src={
                formValues?.photoPath &&
                `https://admin.icba.uk/api/puyblic/documents/${formValues?.photoPath}`
              }
            >
              {!formValues?.photoPath && <AdminIcon />}
            </Avatar>
          ) : (
            <Avatar size="lg">
              <AdminIcon />
            </Avatar>
          )} */}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TextInput name="tcKimlikNo" label={t("pages.student.tcKimlikNo")} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TextInput name="name" label={t("pages.student.name")} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TextInput name="surname" label={t("pages.student.surname")} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TextInput
            name="countryId"
            label={t("pages.student.countryId")}
            accepter={SearchCountry}
          />
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TextInput
            name="genderId"
            label={t("pages.student.genderId")}
            accepter={EnumDropdown}
            data={GenderOptions}
            prefix="general.enums.gender"
          />
        </Col> */}
        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TextInput name="fatherName" label={t("pages.student.fatherName")} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TextInput name="motherName" label={t("pages.student.motherName")} />
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TextInput
            name="placeOfBirth"
            label={t("pages.student.placeOfBirth")}
          />
        </Col>
        {/* <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                    <TextInput
                                        name="birthDate"
                                        style={{ width: "100%" }}
                                        label={t('pages.student.birthDate')}
                                        accepter={DatePicker}
                                        format="dd.MM.yyyy"
                                    // disabledDate={date => isAfter(date, new Date().setDate(new Date().getDate()))}
                                    />
                                </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TextInput name="phone" label={t("pages.student.phone")} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TextInput name="email" label={t("pages.student.email")} />
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TextInput
            name="address"
            label={t("pages.student.address")}
            rows={3}
            accepter={TextArea}
          />
        </Col> */}
      </Panel>
    </Form>
  );
};

export default Profile;
